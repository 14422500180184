exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacte-js": () => import("./../../../src/pages/contacte.js" /* webpackChunkName: "component---src-pages-contacte-js" */),
  "component---src-pages-fires-js": () => import("./../../../src/pages/fires.js" /* webpackChunkName: "component---src-pages-fires-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-llibres-js": () => import("./../../../src/pages/llibres.js" /* webpackChunkName: "component---src-pages-llibres-js" */),
  "component---src-templates-artist-page-js": () => import("./../../../src/templates/artist-page.js" /* webpackChunkName: "component---src-templates-artist-page-js" */)
}

